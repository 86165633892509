<template>
  <base-section id="complain">
    <base-section-heading
      color="grey lighten-2"
      title="Pengaduan"
    />

      <template>
        <v-container>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
        <v-row>
          <v-col
            md = "6"
            sm = "12"
            cols="12"
          >
          <v-text-field
              label="Nomor Polis"
              required="required"
              placeholder="Isi Nomor Polis Anda"
              outlined
              v-model = "nopol"
              :rules  = "nopolRules"
            />
          </v-col>
          <v-col
            md = "6"
            sm = "12"
            cols="12"
          >
          <v-text-field
              label="Nama"
              required="required"
              placeholder="Isi Nama Anda"
              outlined
              v-model = "name"
              :rules  = "nameRules"
            />
          </v-col>
          <v-col
            md = "6"
            sm = "12"
            cols="12"
          >
          <v-text-field
              label="No. Telp/HP"
              required="required"
              placeholder="Isi Nomor Telp/HP Anda"
              outlined
              v-model = "phone"
              :rules  = "phoneRules"
            />
          </v-col>
          <v-col
            md = "6"
            sm = "12"
            cols="12"
          >
          <v-text-field
              label="Email"
              required="required"
              placeholder="Isi Email Anda"
              outlined
              v-model = "email"
              :rules  = "emailRules"
            />
          </v-col>
          <v-col
            md = "12"
            sm = "12"
            cols="12"
          >
          <v-textarea
              label="Catatan"
              required="required"
              placeholder="Isi Catatan Anda"
              outlined
              v-model = "notes"
              :rules  = "notesRules"
            />
          </v-col>
          <v-col
            md = "12"
            sm = "12"
            cols="12"
          >
            <v-btn
            color="secondary"
            tile="tile"
            class="font-weight-bold ml-2 mb-2"
            block
            @click="SaveConfirm"
            >
            Kirim Pengaduan
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
        <v-dialog
          v-model="dialog"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="body-1 grey lighten-2">
              Kirim?
            </v-card-title>

            <v-card-text class="body-2">
              
            </v-card-text>


            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="dialog = false"
              >
                Batal
              </v-btn>
              
              <v-btn
                color="primary"
                text
                :disabled="dialog_ok === 'Ok' ? false:true"
                @click = "SaveData"
              >
                {{dialog_ok}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
            :timeout="snackbar_timeout"
            v-model="snackbar"
          >
            {{ snackbar_text }}
          <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              Close
         </v-btn>
      </v-snackbar>
        </v-container>
      </template>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionComplain',

    data: () => ({
      valid: true,
      dialog: false,
      dialog_ok: 'Ok',
      snackbar: false,
      snackbar_text: '',
      snackbar_timeout: 5000,
      nopol: '',
      nopolRules: [
         v => !!v || 'Mohon masukkan nomor polis anda',
         v => (v && v.length > 3) || 'Nomor polis tidak boleh kurang dari 3 huruf',
      ],
      name: '',
      nameRules: [
         v => !!v || 'Mohon masukkan nama anda',
         v => (v && v.length > 1) || 'Nama tidak boleh 1 huruf',
      ],
      phone: '',
      phoneRules: [
         v => !!v || 'Mohon masukkan nomor handphone anda',
         v => Number.isInteger(Number(v)) || "Mohon isi dengan angka",
      ],
      email: '',
      emailRules: [
         v => !!v || 'Mohon masukkan email anda',
         v => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
      notes: '',
      notesRules: [
         v => !!v || 'Mohon masukkan catatan/pengaduan anda',
         v => (v && v.length > 10) || 'Catatan tidak boleh kurang dari 10 huruf',
      ],
    }),
    methods: {
      SaveConfirm () {
        if (this.$refs.form.validate()) {
          this.dialog = true
        }
      },
      SaveData () {
        this.dialog_ok = 'Loading...'
        var today =  this.$functions.TodayYYYYMMDD()
        let formData = new FormData()
        formData.append('url_type', 'add')
        formData.append('ticket_type', 'T27')
        formData.append('ticket_dept', '027')
        formData.append('ticket_source', 'WEBSITE')
        formData.append('ticket_name', this.name)
        formData.append('ticket_description', this.notes)
        formData.append('ticket_refname', this.name)
        formData.append('ticket_category', 'LAINLAIN')
        formData.append('ticket_pic', 'danang.cahyo')
        formData.append('ticket_subject', 'Ada Tiket Pengaduan dari '+this.name)
        formData.append('ticket_priority', 'H')
        formData.append('ticket_refno', this.nopol)
        formData.append('ticket_address', '')
        formData.append('ticket_email', this.email)
        formData.append('ticket_mobile', this.phone)
        formData.append('ticket_date', today)
        formData.append('ticket_targetdate', today)
        formData.append('ticket_idno', '')
        formData.append('ticket_idtype', '')
        formData.append('ticket_complain', 'Y')
        this.$axios.post(this.$functions.SafeURL('apiWriteTicket', ``), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
        this.dialog = false
        if (response.data[0].feedback === 'Y') {
          this.dialog_text = 'Nomor Pengaduan anda sudah kami register dengan nomor :'+response.data[0].ticket_id
          this.dialog_text = "Ok"
          this.nopol = ''
          this.name = ''
          this.phone = ''
          this.email = ''
          this.notes = ''
        } else {
          this.snackbar = true
          this.snackbar_text = response.data[0].feedback
          this.dialog_text = "Ok"
        }
      })
      .catch(e => {
          this.dialog = false
          this.snackbar = true
          this.snackbar_text = e.response
          this.dialog_text = "Ok"
      })
      },
    }
  }
</script>
